"use client";
import React, { useEffect } from "react";
import { useRouter } from "next/navigation";
const LarkForm = () => {
  const router = useRouter();
  useEffect(() => {
    const handlePostMessage = (event: any) => {
      // Kiểm tra nguồn gốc để đảm bảo bảo mật
      if (event.origin !== "https://ucall-jsc.sg.larksuite.com") return;

      // Kiểm tra dữ liệu trả về từ iframe
      if (event.data.event === "FormSubmitSuccess") {
        // Chuyển hướng sang trang khác khi form được gửi
        router.push("/thank-you-survey");
      }
    };
    window.addEventListener("message", handlePostMessage);

    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, [router]);
  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      <iframe
        src="https://ucall-jsc.sg.larksuite.com/share/base/form/shrlgt70axPLhsJcFaXFUIsFx7t"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        allow="fullscreen"></iframe>
    </div>
  );
};

export default LarkForm;
